import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
  Navigation,
  Pagination,
  Autoplay,
  EffectFade,
} from 'swiper';
import 'swiper/swiper-bundle.min.css';
import styled from 'styled-components';
import { IImageWithTextBlock } from '@/types/contentful/contentful';
import createComponents, { IRest } from '@/utils/createComponents';

const Container = styled.div<IContainer>`
  .swiper-pagination-custom {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
  }

  .swiper-pagination-bullet {
    display: inline-block;
    border-radius: 80px;
    background: ${props => props.theme.paletteDefault.white} !important;
    opacity: 1;
    width: 11px;
    height: 8px;
    border: 1px solid ${props => props.theme.paletteDefault.primary.main};
    margin-right: 16px !important;
    /* &:last-child {
      margin-right: 0px !important;
    } */
  }
  .swiper-pagination-bullet-active {
    background: ${props => props.theme.paletteDefault.primary.main} !important;
  }
  /* .swiper-pagination-custom {
    position: absolute;
    bottom: ${props => (props.tmpl === 'banner_2_columns' ? '0' : '16px')};
    z-index: 9;
    display: flex;
    justify-content: center;
    width: ${props => (props.tmpl === 'banner_2_columns' ? '100%' : '100%')};
    ${props => props.theme.breakpoints.up('sm')} {
      bottom: 32px;
      width: ${props => (props.tmpl === 'banner_2_columns' ? '40%' : '100%')};
    }
  }

  .swiper-pagination-bullet {
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 100%;
    background: ${props =>
    props.tmpl === 'banner_2_columns'
      ? props.theme.paletteDefault.primary.main + ' !important'
      : props.theme.paletteDefault.white + '!important'};
    opacity: 1;

    margin-right: 12px !important;
    &:last-child {
      margin-right: 0px !important;
    }
  } */

  .swiper-button-next {
    background-repeat: no-repeat;
    background-image: url(/asset/v2/arrow_right.svg);
    background-position: center center;
    width: 32px;
    height: 32px;
    background-color: ${props => props.theme.paletteDefault.white};

    right: 0;
    display: none;
    ${props => props.theme.breakpoints.up('sm')} {
      display: block;
    }
  }

  .swiper-button-prev {
    background-repeat: no-repeat;
    background-image: url(/asset/v2/arrow_left.svg);
    background-position: center center;
    width: 32px;
    height: 32px;
    background-color: ${props => props.theme.paletteDefault.white};

    left: 0;
    display: none;
    ${props => props.theme.breakpoints.up('sm')} {
      display: ${props =>
        props.tmpl === 'banner_2_columns' ? 'none' : 'block'};
    }
  }

  .swiper-button-next::after {
    display: none;
  }

  .swiper-button-prev::after {
    display: none;
  }
`;

SwiperCore.use([Navigation, Pagination, Autoplay, EffectFade]);

interface IContainer {
  tmpl: string;
}

interface ISliderNew {
  fields: {
    autoplay: boolean;
    showPageDots: boolean;
    showArrows: boolean;
    template: string;
    imageWithTextBlock: IImageWithTextBlock[];
    index?: number;
  };
}

const Slider: React.FC<ISliderNew> = props => {
  const { imageWithTextBlock, autoplay, showPageDots, showArrows, index } =
    props.fields;

  let tmpl = '';

  if (imageWithTextBlock && imageWithTextBlock.length > 0) {
    tmpl = imageWithTextBlock[0]?.fields?.template;
  }

  let show_arrows = imageWithTextBlock?.length === 1 ? false : showArrows;
  let loop = imageWithTextBlock?.length === 1 ? false : true;
  let auto_play = autoplay
    ? {
        delay: 10000,
      }
    : false;

  const pagination =
    imageWithTextBlock?.length > 1 && showPageDots
      ? {
          el: '.swiper-pagination-custom',
          clickable: true,
          renderBullet: function (_: number, className: string) {
            return '<div class="' + className + '">' + '</div>';
          },
        }
      : showPageDots;

  return (
    <>
      <Container tmpl={tmpl}>
        {imageWithTextBlock?.length > 1 ? (
          <Swiper
            navigation={show_arrows}
            pagination={pagination}
            autoplay={auto_play}
            loop={loop}
            speed={600}
            effect={'fade'}
            fadeEffect={{
              crossFade: true,
            }}>
            {imageWithTextBlock?.map((slide: any, i: number) => {
              const rest: IRest = {
                index: index === 0 ? i : undefined,
              };
              return slide ? (
                <SwiperSlide key={slide.sys.id}>
                  {createComponents(slide, rest)}
                </SwiperSlide>
              ) : null;
            })}
            <div className="swiper-pagination-custom"></div>
          </Swiper>
        ) : (
          imageWithTextBlock?.map(slide => {
            return slide ? createComponents(slide) : null;
          })
        )}
      </Container>
    </>
  );
};

export default Slider;
